<template>
  <main class="page">
    <div class="page__grid">
      <ProgressBar :percent="25" class="progress--header"></ProgressBar>

      <h1 class="page__header">Opening Greeting</h1>

      <p class="page__description">
        Choose document caption/header from the following or add your own custom
        header
      </p>

      <div class="step">
        <p class="step__text">Choose a greeting</p>
        <div class="step__input">
          <div>
            <div
              class="field"
              v-for="(greeting, index) in greetings"
              :key="index"
            >
              <div
                class="
                  field__wrap
                  field__wrap-checkbox
                  field__wrap-checkbox--multiline
                "
              >
                <div>
                  <input
                    class="field__checkbox"
                    type="radio"
                    :id="`checkbox${index}`"
                    name="greeting"
                    placeholder="Name"
                    :value="greeting"
                    autocomplete="false"
                    v-model="options"
                    required
                  />
                  <label :for="`checkbox${index}`"><span></span></label>
                </div>
                <label :for="`checkbox${index}`">{{ greeting }}</label>
              </div>
            </div>
            <div class="field">
              <div class="field__wrap field__wrap-checkbox">
                <div>
                  <input
                    class="field__checkbox"
                    type="radio"
                    id="checkbox5"
                    name="greeting"
                    placeholder="Name"
                    value="customGreeting"
                    autocomplete="false"
                    v-model="options"
                    required
                  />
                  <label for="checkbox5"><span></span></label>
                </div>
                <label for="checkbox5">Write your own</label>
              </div>
            </div>
            <div class="field" v-show="customHeader.show">
              <div class="field__wrap">
                <textarea
                  name="opening_greeting"
                  class="field__textarea field__textarea-check-triggerred"
                  id=""
                  cols="30"
                  rows="10"
                  v-model="greeting"
                  placeholder="Custom Opening Greeting"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="step__btn">
          <router-link
            :to="{ name: 'DocumentCaption' }"
            class="button button--secondary"
            >Go Back</router-link
          >
          <button
            @click="routeToNextPage"
            class="button button--primary"
            :disabled="!validateInput"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
    <div class="page__grid">
      <img
        src="@/assets/img/greeting.svg"
        class="page__grid--image"
        alt="Legacy Composer Home"
      />
    </div>
  </main>
</template>

<script>
import storage from '@/utils/storage'
import ProgressBar from '@/components/ui/progress-bar'

export default {
  name: 'Greeting',
  components: { ProgressBar },
  data() {
    return {
      options: '',
      greeting: '',
      customHeader: {
        show: false,
      },
      greetings: [
        'My Dear children and grandchildren. In my love and desire for your future blessing and prosperity, I have written below some words of advice and encouragement that I think will be a blessing to you and your children. Please read this regularly on some family occasion that you find meaningful.',
        'To the future of our family. All our hopes and dreams are contained in you. It is our hope that as you live out your own lives, you will be guided by some of the experiences of the previous generations; some of which I set out here',
        'My Dear children and grandchildren. I and members of my generation wish to live long to see your progress and watch over your children. We however know that life is uncertain and that it is wise to send to you some advice, our best wishes, and prayers today and not tomorrow. Please receive our advice in the humility and thankfulness in which we send it to you',
        'My Dear Grandchildren, I wish I could be certain of seeing you grow into the lovely young people of my dreams – maybe that will happen. I hope it does. But since today is certain and sure, I thought to send to you this letter of advice and direction in the hope that it might be a light to your path and a silent guide in moments of decision. Here are my thoughts for you',
      ],
    }
  },
  created() {
    let storedData = storage.getDataFromLocalStorage('greeting')
    if (storedData) {
      this.options = storedData
    }
    if (!this.greetings.includes(this.options) && this.options !== '') {
      this.options = 'customGreeting'
      this.greeting = storedData
    }
  },
  methods: {
    routeToNextPage() {
      storage.storeDataToLocalStorage('greeting', this.greeting)
      this.$router.push({ name: 'Lessons' })
    },
  },
  computed: {
    validateInput() {
      return this.greeting !== ''
    },
  },
  watch: {
    options: function (value) {
      this.greeting = ''
      this.customHeader.show = !this.greetings.includes(value)
      if (!this.customHeader.show) this.greeting = value
    },
  },
}
</script>
